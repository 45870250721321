import * as am5 from '@amcharts/amcharts5';
import { pieChartData } from './chart.modal';

function getLabelClassHelper(color: string, prefix: string): string {
  switch (color) {
    case '#90DD75':
      return `${prefix}-light-green`;
    case '#28A745':
      return `${prefix}-green`;
    case '#17A2B8':
      return `${prefix}-light-blue`;
    case '#007BFF':
      return `${prefix}-blue`;
    case '#FFC107':
      return `${prefix}-yellow`;
    case '#FFA500':
      return `${prefix}-dark-yellow`;
    case '#6C757D':
      return `${prefix}-gray`;
    case '#343A40':
      return `${prefix}-dark-gray`;
    case '#DC3545':
      return `${prefix}-red`;
    case '#FF0000':
      return `${prefix}-dark-red`;
    case '#E0554D':
      return `${prefix}-dark-red`;
    case '#D6A2E8':
      return `${prefix}-light-purple`;
    case '#6F42C1':
      return `${prefix}-purple`;
    case '#FF9F45':
      return `${prefix}-light-orange`;
    case '#FD7E14':
      return `${prefix}-orange`;
    case '#D2B48C':
      return `${prefix}-light-brown`;
    default:
      return ``;
  }
}

export function getStatusLabelClass(color: string): string {
  return getLabelClassHelper(color, 'status');
}

export function getTextLabelClass(color: string): string {
  return getLabelClassHelper(color, 'text');
}

export function getBoxColorClass(color: string): string {
  return getLabelClassHelper(color, 'box');
}

export function getBGColorclass(color: string): string {
  return getLabelClassHelper(color, 'bg');
}

export function convertResponseToChartData(
  response: any,
  lang: string
): pieChartData {
  return {
    category: response[`title_${lang}`],
    count: response.count,
    sliceSettings: {
      fill: am5.color(response.color_code),
    },
  };
}
